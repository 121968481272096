import Error from 'next/error'
import dynamic from 'next/dynamic'
import usePushPageView from '@/hooks/Events/pageViewHooks'
import { getPageData } from '@/lib/contentful/content'
import CustomLoading from '@/components/atoms/CustomLoading'


const DynamicLayout = dynamic(() => import('@/components/Layout/Layout'), {
  loading: () => <CustomLoading />,
  ssr: false,
})

const ModuleScriptHead = dynamic(
  () => import('@/components/ModuleScriptHead/ModuleScriptHead').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: false,
  }
)

const Modules = dynamic(() => import('@/components/Modules/Modules'))

const PAGE_SLUG = '404'

const Index = ({ page, preview }) => {
  if (page) {
    let { modules = null, layoutData } = page
    usePushPageView({
      page_type: '404',
      page_sub_type: '404',
      page_title: page?.title || '404',
    })
    return (
      <DynamicLayout layoutData={layoutData} preview={preview}>
        {modules ? (
          <Modules modules={modules} preview={preview} />
        ) : (
          <div className="container w-full my-10 lg:my-20">
            <div className="col-span-1 text-xl text-center">404 - Page Not Found</div>
          </div>
        )}
        {page?.headInsert && <ModuleScriptHead headInsert={page?.headInsert} id={page?.slug} />}
      </DynamicLayout>
    )
  }

  return <Error statusCode={404} />
}

export default Index

export const getStaticProps = async ({ locale, preview }) => {
  const page = await getPageData(PAGE_SLUG)

  return {
    props: {
      page,
      preview: preview ? preview : null,
    },
  }
}
