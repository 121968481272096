const CustomLoading = () => {
  return (
    <div
      className={`search-dropdown flex justify-center items-center bg-white z-[999999999] absolute w-full h-screen`}
    >
      <div className="loader-product ml-[36px] mt-[24px]" />
    </div>
  )
}

export default CustomLoading
